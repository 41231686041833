<template>
	<div>
		<b-row>
			<b-col class="d-flex justify-content-md-between mb-1" cols="12">
				<div>
					<h3>Liste de vos blogs</h3>
				</div>
				<div>
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						v-b-modal.modal-create-blog
						variant="primary"
						@click="startAddBlog"
					>
						Ajouter un blog
					</b-button>
				</div>
			</b-col>
			<b-col cols="12">
				<blogs-list />
			</b-col>
		</b-row>
		<b-modal
            id="modal-create-blog"
            title="Nouveau blog"
			hide-footer
        >
			<b-overlay
				:show="is_loading"
			>
				<validation-observer
					ref="addBlogRef"
					#default="{ invalid }"
				>
					<b-form>
						<b-form-group
							label="Nom du blog"
						>
							<validation-provider
								#default="{ errors }"
								name="name"
								rules="required"
							>
								<b-form-input
									v-model="newBlog.name"
									placeholder="Nom de votre blog"
								/>
								<small class="text-danger">{{ errors[0]}}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Domaine du blog"
						>
							<validation-provider
								#default="{ errors }"
								name="domain"
								rules="required|url"
							>
								<b-input-group
									prepend="https://"
								>
									<b-form-input
										v-model="newBlog.domain"
										placeholder="Domaine de votre blog"
									/>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Nom d'utilisateur"
							description="Un utilisateur ayant les permissions de créer des articles est requis"
						>
							<validation-provider
								#default="{ errors }"
								name="wpusername"
								rules="required"
							>
								<b-form-input
									v-model="newBlog.wpUserName"
									placeholder="Nom d'utilisateur"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Mot de passe de l'utilisateur"
						>
							<validation-provider
								#default="{ errors }"
								name="wppassword"
								rules="required"
							>
								<b-form-input
									v-model="newBlog.wpPassword"
									placeholder="Mot de passe de l'utilisateur"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<div class="d-flex justify-content-end">
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="outline-secondary"
								class="mr-50"
								:disabled="is_loading"
								@click="$bvModal.hide('modal-create-blog')"
							>
								Annuler
							</b-button>
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="primary"
								:disabled="invalid"
								@click="addBlog"
							>
								Ajouter le blog
							</b-button>
						</div>
					</b-form>
				</validation-observer>
			</b-overlay>
        </b-modal>
	</div>
</template>

<script>
import {
	BRow,
	BCol,
	BButton,
	BModal,
	VBModal,
	BFormGroup,
	BFormInput,
	BInputGroup,
	BOverlay,
	BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
	required,
	url,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { auth, database } from '@/firebase.config'
import blogsList from './BlogsList.vue'

export default {
	directives: {
        'b-modal': VBModal,
        Ripple,
	},
	components: {
		BRow,
		BCol,
		BButton,
		BModal,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BOverlay,
		BForm,
		ValidationProvider,
        ValidationObserver,
        blogsList,
	},
	data() {
		return {
			is_loading: false,
			newBlog: {
				name: '',
				domain: '',
				wpUserName: '',
				wpPassword: '',
			},
			required,
			url,
		}
	},
	mounted() {
		console.log(this)
	},
	methods: {
		startAddBlog() {
			Object.assign(this.$data, this.$options.data())
		},
		addBlog() {
			this.is_loading = true
			database.collection('blogs').doc().set({
				userId: auth.currentUser.uid,
				name: this.newBlog.name,
				domain: this.newBlog.domain,
				wpUserName: this.newBlog.wpUserName,
				wpPassword: this.newBlog.wpPassword,
			})
			.then(() => {
				this.notify('Blog ajouté avec succès !')
				Object.assign(this.$data, this.$options.data())
				this.$bvModal.hide('modal-create-blog')
			})
			.catch(error => {
				console.log(error)
				this.notify('Erreur', 'danger', error.message)
            })
            .finally(() => {
                this.is_loading = false
            })
		},
	},
}
</script>

<style>

</style>
