<template>
    <div>
        <b-row>
            <b-col
                v-for="blog in $store.getters.blogs"
                :key="blog.id"
                md="6"
                lg="4"
                xl="3"
            >
                <b-card
                    no-body
                >
                    <b-card-header>
                        <b-card-title>
                            {{ blog.name }}
                        </b-card-title>
                        <feather-icon
                            v-b-modal.modal-edit-blog
                            icon="SettingsIcon"
                            size="20"
                            class="cursor-pointer"
                            @click="startEditBlog(blog)"
                        />
                    </b-card-header>
                    <b-card-body>
                        <b-link
                            target="_blank"
                            rel="noopener noreferer"
                            :href="`https://${blog.domain}`"
                        >
                            https://{{ blog.domain }}
                        </b-link>
                        <!-- <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            class="mt-50"
                            :to="{ name: 'blog-write-article', params: { id: blog.id } }"
                        >
                            Créer un article
                        </b-button> -->
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            class="mt-50"
                            :to="{ name: 'blog-automation-article', params: { id: blog.id } }"
                        >
                            Ajouts automatique
                        </b-button>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-modal
            id="modal-edit-blog"
            title="Modifier ce blog"
			hide-footer
        >
			<b-overlay
				:show="is_loading"
			>
				<validation-observer
					ref="editBlogRef"
					#default="{ invalid }"
				>
					<b-form>
						<b-form-group
							label="Nom du blog"
						>
							<validation-provider
								#default="{ errors }"
								name="name"
								rules="required"
							>
								<b-form-input
									v-model="editBlog.name"
									placeholder="Nom de votre blog"
								/>
								<small class="text-danger">{{ errors[0]}}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Domaine du blog"
						>
							<validation-provider
								#default="{ errors }"
								name="domain"
								rules="required|url"
							>
								<b-input-group
									prepend="https://"
								>
									<b-form-input
										v-model="editBlog.domain"
										placeholder="Domaine de votre blog"
									/>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Nom d'utilisateur"
							description="Un utilisateur ayant les permissions de créer des articles est requis"
						>
							<validation-provider
								#default="{ errors }"
								name="wpusername"
								rules="required"
							>
								<b-form-input
									v-model="editBlog.wpUserName"
									placeholder="Nom d'utilisateur"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Mot de passe de l'utilisateur"
						>
							<validation-provider
								#default="{ errors }"
								name="wppassword"
								rules="required"
							>
								<b-form-input
									v-model="editBlog.wpPassword"
									placeholder="Mot de passe de l'utilisateur"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<div class="d-flex justify-content-end">
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="outline-secondary"
								class="mr-50"
								:disabled="is_loading"
								@click="$bvModal.hide('modal-edit-blog')"
							>
								Annuler
							</b-button>
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="primary"
								:disabled="invalid"
                                @click="saveBlog"
							>
								Enregistrer
							</b-button>
						</div>
					</b-form>
				</validation-observer>
			</b-overlay>
        </b-modal>
    </div>
</template>

<script>

import {
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BCardBody,
    BLink,
    BButton,
    BModal,
    VBModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    BForm,
} from 'bootstrap-vue'
import {
	required,
	url,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { database as db, auth } from '@/firebase.config'

export default {
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BRow,
        BCol,
        BCardBody,
        BLink,
        BButton,
        BModal,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BOverlay,
		BForm,
		ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
	},
    data() {
        return {
            is_loading: true,
            blogs: [],
            editBlog: {
                name: '',
				domain: '',
				wpUserName: '',
				wpPassword: '',
            },
            required,
			url,
        }
    },
    created() {
        db.collection('blogs').where('userId', '==', auth.currentUser.uid)
        .onSnapshot(querySnapshot => {
            this.is_loading = false
            // On mets à zéro la liste des blogs pour la remplir juste après
            this.$store.commit('blogs', [])
            const blogs = []
            querySnapshot.forEach(doc => {
                const bd = doc.data()
                bd.id = doc.id
                blogs.push(bd)
            })
            this.$store.commit('blogs', blogs)
        }, err => {
            this.is_loading = false
            this.notify('Erreur', 'danger', err.message)
        })
    },
    methods: {
        saveBlog() {
            this.is_loading = true
            db.collection('blogs').doc(this.editBlog.id).set(this.editBlog)
            .then(() => {
                this.notify('Modifications enregistrées avec succès !')
                this.$bvModal.hide('modal-edit-blog')
            })
            .catch(err => {
                this.notify('Erreur', 'danger', err.message)
            })
            .finally(() => {
                this.is_loading = false
            })
        },
        startEditBlog(blog) {
            this.editBlog = { ...blog }
        },
    },
}
</script>
